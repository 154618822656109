<template>
  <ul class="nav nav-pills">
    <li class="nav-item">
      <router-link class="nav-link" to="/">Highlights</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" to="/experience">Experience</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" to="/projects">Projects</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" to="/open-source">Open source</router-link>
    </li>
  </ul>
</template>
